import React from "react";

class RoutingErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        };
    }

    static getDerivedStateFromError(error) {
        // Return state reflecting the error occurrence
        return { hasError: true, error };
    }
  
    componentDidCatch(error, errorInfo) {
        // Log or report the error for debugging
        console.error('Routing error:', error, errorInfo);
    }
  
    render() {
        if (this.state.hasError) {
            // Provide your fallback UI here
            return <div>Uh oh, something went wrong with routing!</div>;
        }
        return this.props.children;
    }
}

export default RoutingErrorBoundary;
