import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  CloseButton,
} from "react-bootstrap";
import CustomImage from "../components/CustomImage";
import Loading from "../components/Loading";
import "./Survey.css";
import { authenticatedFetch } from "../helpFunctions/authFetch";
import { getEnvVariable } from "../helpFunctions/getEnvVariable";

const Survey = () => {
  const [files, setFiles] = useState({ current: [], next: [] });
  const [itemType, setItemType] = useState({}); // 'image' or 'video'
  const [variantsID, setVariantsId] = useState([]);
  const [alertMessage, setAlertMessage] = useState({});
  const [alertAnimation, setAlertAnimation] = useState(false);

  const [isSend, setIsSend] = useState(true);
  const [selection, setSelection] = useState(""); // 0, 1, or ''
  const surveyId = useParams().surveyId; // Extract survey ID from URL
  const apiBaseUrl = getEnvVariable("API_BASE_URL");

  const navigate = useNavigate();

  const fetchImage = async (mode) => {
    setItemType((prevItemType) => ({
      current: prevItemType.next,
      next: prevItemType.next,
    }));
    setFiles((prevFiles) => ({
      current: prevFiles.next,
      next: prevFiles.next,
    }));
    try {
      const response = await authenticatedFetch(
        navigate,
        `${apiBaseUrl}survey/${surveyId}`
      );
      const resJson = await response.json();
      console.log("🚀 ~ fetchImage ~ resJson:", resJson);
      if (!response.ok) {
        throw resJson;
      }
      if (mode === "first") {
        console.log("first");
        setItemType({ current: resJson.file_type });
        setFiles({ current: resJson.files, next: [] });
      } else {
        if (mode === "vote") {
          console.log("after vote");
          setIsSend(true);
        }
        console.log("next fetch");
        setItemType((prevFiles) => ({
          current: prevFiles.current,
          next: resJson.file_type,
        }));
        setFiles((prevFiles) => ({
          current: prevFiles.current,
          next: resJson.files,
        }));

        setSelection("");
      }

      setVariantsId(resJson.variants_id);
    } catch (error) {
      if (
        error.detail ===
        "ValueError('The survey must contain at least two active variants')"
      ) {
        setAlertMessage({
          type: "warning",
          header: "Survey Unavailable",
          msg: "This survey requires at least two active variants to proceed.",
        });
      } else {
        setAlertMessage({
          type: "danger",
          header: "Error",
          msg: `There has been a problem: ${error.detail}`,
        });
      }
    }
  };

  useEffect(() => {
    fetchImage("first");
    fetchImage("next");
  }, []);

  //   useEffect(()=>{
  //     if (!files.next.length) {
  //         fetchImage()
  //     }
  //   }, [files.next.length])

  const ImagePreloader = ({ imageUrl }) => {
    useEffect(() => {
      const img = new Image();
      img.src = imageUrl;
    }, [imageUrl]);

    return null; // This component doesn't render anything
  };

  const VideoPreloader = ({ videoUrl }) => {
    useEffect(() => {
      const fetchVideo = async () => {
        try {
          const response = await fetch(videoUrl, { mode: "no-cors" });
          // Read the response as a blob
          await response.blob();
        } catch (error) {
          console.error("Failed to pre-fetch video:", error);
        }
      };

      fetchVideo();
    }, [videoUrl]);

    return null; // This component doesn't render anything
  };

  const sendVote = async () => {
    const getScreenResolution = () => {
      // Get screen width and height using window object
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      return `${width}x${height}`;
    };

    const response = await authenticatedFetch(navigate, `${apiBaseUrl}vote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        survey_id: surveyId,
        variant_id: variantsID[selection],
        device: /Mobile/.test(navigator.userAgent) ? "Mobile" : "Desktop",
        screen_resolution: getScreenResolution(),
      }),
    });
    if (response.ok) {
      setAlertMessage({
        type: "success",
        header: "Vote Recorded",
        msg: "Your vote has been successfully recorded. Thank you for participating!",
      });
      setTimeout(() => {
        setAlertAnimation(true);
        setTimeout(() => setAlertMessage(""), 500);
        setAlertAnimation(false);
      }, 4000);
    } else {
      const resJson = await response.json();
      setAlertMessage({
        type: "danger",
        header: "Error Recording Vote",
        msg: resJson.message || "Failed to record your vote.",
      });
    }
  };

  // Handler for "Next" button click
  const handleNext = () => {
    setIsSend(false);
    if (selection !== "") sendVote();
    fetchImage("vote");
  };

  return (
    <div className="text-center justify-content-center">
      {/* Conditional rendering based on image loading status */}
      {!files.current.length && !alertMessage.msg ? (
        <Loading />
      ) : (
        <div>
          <Row className="h1 mb-2">
            <header>Which is better?</header>
          </Row>
          <Container className="mb-5 justify-content-center d-flex flex-column flex-md-row">
            {files.current &&
              files.current.map((file, index) => {
                const viewIndex = index + 1;
                const isSelected = selection === index && isSend;
                const borderConfig = "border border-primary border-5";
                return (
                  <Container
                    key={index}
                    className="me-2 pointer-cursor"
                    onClick={(e) => {
                      e.preventDefault();
                      selection === index
                        ? setSelection("")
                        : setSelection(index);
                    }}
                  >
                    {/* Render image or video based on item type */}
                    {itemType.current === "image" && (
                      <CustomImage
                        className={isSelected ? borderConfig : ""}
                        src={files.current[index]}
                        alt={`Item ${viewIndex}`}
                        fluid
                        rounded={2}
                        preload={"auto"}
                      />
                    )}
                    {itemType.current === "video" && (
                      <video
                        controls
                        className={isSelected ? borderConfig : ""}
                        preload={"auto"}
                        src={files.current[index]}
                        type="video/mp4"
                        alt={`Item ${viewIndex}`}
                      >
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <p className="cursor-pointer">Item {viewIndex}</p>
                  </Container>
                );
              })}
          </Container>
          <Row lg={3} xl={4} className="mb-5 justify-content-center">
            {/* Undo button */}
            <Col className="text-start">
              <Button
                variant="secondary"
                onClick={() => setSelection("")}
                disabled={selection === ""}
              >
                Undo
              </Button>
            </Col>
            {/* Next or Skip button based on selection */}
            <Col className="text-end">
              <Button
                variant={selection === "" ? "secondary" : "primary"}
                onClick={isSend ? handleNext : null}
                disabled={
                  !isSend || alertMessage.header === "Survey Unavailable"
                }
              >
                {selection === ""
                  ? isSend
                    ? "Skip"
                    : "Skiping..."
                  : isSend
                  ? "Next"
                  : "Sending..."}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <Row sm={3} className="ms-2 position-fixed top-0 start-0 z-1000">
        {alertMessage.msg && (
          <Alert
            variant={alertMessage.type}
            className={`${alertMessage.msg && "alert-animate-enter"} ${
              alertAnimation && "alert-animate-exit"
            }`}
          >
            {alertMessage.header !== "Survey Unavailable" && (
              <div className="d-flex justify-content-end">
                <CloseButton
                  onClick={() => {
                    setAlertAnimation(true);
                    setTimeout(() => setAlertMessage(""), 300);
                    setAlertAnimation(false);
                  }}
                />
              </div>
            )}
            <Alert.Heading className="text-start">
              {alertMessage.header}
            </Alert.Heading>
            <p className="text-start">{alertMessage.msg}</p>
          </Alert>
        )}
      </Row>
    </div>
  );
};

export default Survey;
