import React, { useState, useEffect } from "react";
import { Container, Button, ListGroup, Card, Badge } from "react-bootstrap";
import Loading from "../components/Loading";
import SurveyWithVariants from "../data/SurveyWithVariants";
import fetchData from "../helpFunctions/fetchData";
import SurveyConfig from "../components/SurveyConfig";
import { convertOutData } from "../helpFunctions/convertOutData";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";

const SurveyManagementScreen = () => {
  const [viewMode, setViewMode] = useState("loading"); // 'loading', 'alert', 'list', 'create' or 'update'
  const [selectedSurvey, setSelectedSurvey] = useState(0);
  const [surveys, setSurveys] = useState([new SurveyWithVariants()]);
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    header: "",
    msg: "",
  });
  const [serviceAccountEmail, setServiceAccountEmail] = useState("");

  const navigate = useNavigate();

  // extract the base front url
  let baseUrl = window.location.host;
  if (!baseUrl.endsWith("/")) {
    baseUrl += "/";
  }

  useEffect(() => {
    const fetchServiceAccount = async () => {
      const serviceAccount = await fetchData(
        navigate,
        "GET",
        setSurveys,
        setViewMode,
        setAlertMessage
      );
      console.log("🚀 ~ fetchServiceAccount ~ serviceAccount:", serviceAccount);
      setServiceAccountEmail(serviceAccount);
    };
    fetchServiceAccount();
  }, [navigate]);

  const handleSurveySelect = (index) => {
    setSelectedSurvey(index);
    setViewMode("update");
  };

  const handleSurveyChange = (surveyData) => {
    const method =
      viewMode === "create" ? "POST" : isNaN(surveyData) ? "PUT" : "DELETE";
    console.log("🚀 ~ handleSurveyChange ~ method:", method);
    const body =
      method === "POST" || method === "PUT"
        ? JSON.stringify(convertOutData(surveyData))
        : "";
    console.log("🚀 ~ handleSurveyChange ~ body:", body);

    fetchData(
      navigate,
      method,
      setSurveys,
      setViewMode,
      setAlertMessage,
      body,
      surveyData,
      surveys
    );
  };

  const copyLinkToClipboard = (surveyId) => {
    const votersLink = `${baseUrl}survey/${surveyId}`;

    if (navigator?.clipboard?.writeText) {
      navigator.clipboard
        .writeText(votersLink)
        .then(() => {
          setAlertMessage({
            type: "success",
            header: "Link copied to clipboard!",
          });
          setViewMode("alert");
        })
        .catch((error) => {
          console.error("Failed to copy link: ", error);
        });
    } else {
      copyTextToClipboard(votersLink);
    }
  };

  const copyTextToClipboard = (text) => {
    // Create a textarea element
    const textarea = document.createElement("textarea");
    textarea.value = text;

    // Set the textarea to be invisible
    textarea.style.position = "fixed";
    textarea.style.opacity = 0;

    // Append the textarea to the document body
    document.body.appendChild(textarea);

    // Select the text within the textarea
    textarea.select();

    try {
      // Copy the selected text to the clipboard
      document.execCommand("copy");
      alert("Text copied to clipboard:", text);
    } catch (error) {
      alert("Failed to copy text to clipboard:", error);
    } finally {
      // Remove the textarea from the document body
      document.body.removeChild(textarea);
    }
  };

  return (
    <Container className="my-4" style={{ maxWidth: "800px" }}>
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mb-4">
            <h1 className="mb-3 mb-md-0">Survey Management</h1>
            <div>
              {viewMode === "list" && (
                <Button variant="primary" onClick={() => setViewMode("create")}>
                  <i className="fas fa-plus me-2"></i>Create Survey
                </Button>
              )}
              {(viewMode === "create" || viewMode === "update") && (
                <Button variant="secondary" onClick={() => setViewMode("list")}>
                  <i className="fas fa-arrow-left me-2"></i>Back to List
                </Button>
              )}
            </div>
          </div>

          {viewMode === "alert" && (
            <Alert
              variant={alertMessage.type}
              onClose={() => setViewMode("list")}
              dismissible
            >
              <Alert.Heading>{alertMessage.header}</Alert.Heading>
              <p>{alertMessage.msg}</p>
            </Alert>
          )}

          {viewMode === "loading" && <Loading />}

          {viewMode === "list" && (
            <div>
              {surveys.length === 0 ? (
                <p className="text-center">No surveys exist</p>
              ) : (
                <ListGroup>
                  <h2 className="mb-3">Surveys</h2>
                  {surveys.map((survey, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      onClick={() => {
                        if (survey.user_role === "manager") {
                          handleSurveySelect(index);
                        }
                      }}
                      className="mb-2"
                    >
                      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                        <div className="mb-2 mb-md-0">
                          <Badge bg="secondary" className="me-2">
                            ID: {survey.surveyId}
                          </Badge>
                          <strong>{survey.surveyName}</strong>
                        </div>
                        <div className="d-flex">
                          <Button
                            className="me-2"
                            variant="outline-warning"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyLinkToClipboard(survey.surveyId);
                            }}
                            disabled={survey.user_role === "viewer"}
                          >
                            <i className="fas fa-link me-1"></i>Vote Link
                          </Button>
                          <Button
                            as="a"
                            target="_blank"
                            variant="outline-info"
                            size="sm"
                            href={`/results/${survey.surveyId}`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <i className="fas fa-chart-bar me-1"></i>Results
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </div>
          )}

          {(viewMode === "create" || viewMode === "update") && (
            <SurveyConfig
              surveyData={
                viewMode === "update"
                  ? surveys[selectedSurvey]
                  : new SurveyWithVariants()
              }
              handleSurveyChange={handleSurveyChange}
              serviceAccountEmail={serviceAccountEmail}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SurveyManagementScreen;
