
class BaseSurvey {
  constructor({
    surveyId = '',
    surveyName = '',
    source = 'GCS',
    folder = '',
    apiKey = '', 
    variantActiveByDefault = true,
    createdBy = null,
    createdAt = null
  } = {}) {
    this.surveyId = surveyId;
    this.surveyName = surveyName;
    this.source = source;
    this.folder = folder;
    this.apiKey = apiKey;
    this.variantActiveByDefault = variantActiveByDefault;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
  }
}

export default BaseSurvey;