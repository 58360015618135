import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getEnvVariable } from '../helpFunctions/getEnvVariable';

function LogIn() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const onSuccess = (credential) => {
        console.log('Login successful:', credential);
        setIsLoading(true);
        fetch(`${getEnvVariable('API_BASE_URL')}auth/google`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ credential: credential }),
          })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            // Store the token in localStorage
            localStorage.setItem('authToken', data.token);
            navigate('/auth-success');
          })
          .catch((error) => {
            console.error('Error:', error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
      
      const onError = (error) => {
        console.error('Login failed:', error);
        // Handle login errors here, e.g., display error message to the user
      };
      

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
            <div className="card p-4 shadow">
                <h2 className="text-center mb-4">Welcome</h2>
                {isLoading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-2">Login successful. Please wait while we redirect you. It may take a few seconds...</p>
                    </div>
                ) : (
                    <div>
                        <p className="text-center mb-4">Please sign in to continue</p>
                        <GoogleLogin
                            buttonText="Sign in with Google"
                            onSuccess={credentialResponse => {
                                console.log(credentialResponse);
                                onSuccess(credentialResponse.credential);
                            }}
                            onError={onError}
                            cookiePolicy="single_host_origin" // Optional: set cookie policy
                            auto_select={true}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default LogIn