import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import RoutingErrorBoundary from './RoutingErrorBoundary';

import LogIn from './layout/LogIn';
import Survey from './layout/Survey';
import SurveyManagementScreen from './layout/Managment';
import Results from './layout/results';

function App() {
  return (
    <div className='mt-4'>
      <BrowserRouter>
        <RoutingErrorBoundary>
          <Routes>
            <Route path="*" element={<SurveyManagementScreen />} />
            <Route path="/" element={<SurveyManagementScreen />} />
            <Route path="/survey/:surveyId" element={<Survey />} />
            <Route path='/manage/' element={<SurveyManagementScreen />} />
            <Route path='/results/:surveyId' element={<Results />} />
            <Route path='/login/' element={<LogIn />} />
            <Route path='/auth-success' element={<Navigate to="/manage" replace />} />
          </Routes>
        </RoutingErrorBoundary>
      </BrowserRouter>
    </div>
  );
}

export default App;
