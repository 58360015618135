class Variant {
  constructor({
    variantId = "",
    variantName = "",
    isActive = true,
    wandbId = "",
  } = {}) {
    this.variantId = variantId;
    this.variantName = variantName;
    this.isActive = isActive;
    this.wandbId = wandbId;
  }
}

export default Variant;
