export const authenticatedFetch = (navigate, url, options = {}) => {
  const token = localStorage.getItem("authToken");
  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.status === 401) {
      // Clear the token and redirect to login
      localStorage.removeItem("authToken");
      navigate("/login");
      alert(
        "You are not authorized to access this page. Please login to continue."
      );
    }
    return response;
  });
};
