import BaseSurvey from "./BaseSurvey";
import Variant from "./Variant";

class SurveyWithVariants extends BaseSurvey {
  constructor({
    surveyId = "",
    surveyName = "",
    source = "GCS",
    //    selectedProject = '',
    folder = "",
    apiKey = "",
    variantActiveByDefault = false,
    variants = [new Variant()],
    access = [],
    user_role = "",
  } = {}) {
    super({
      surveyId,
      surveyName,
      source,
      folder,
      apiKey,
      variantActiveByDefault,
    });

    this.variants = variants;
    this.access = access.map((a) => new SurveyAccess(a));
    this.user_role = user_role;
  }

  getSurveyProperties() {
    return {
      surveyId: this.surveyId,
      surveyName: this.surveyName,
      source: this.source,
      //    selectedProject: this.selectedProject,
      folder: this.folder,
      apiKey: this.apiKey,
      variantActiveByDefault: this.variantActiveByDefault,
      user_role: this.user_role,
    };
  }

  getAllVariants() {
    return this.variants;
  }

  getAccess() {
    return this.access;
  }

  addAccess(access) {
    this.access.push(new SurveyAccess(access));
  }

  removeAccess(accessId) {
    this.access = this.access.filter((a) => a.accessId !== accessId);
  }
}

class SurveyAccess {
  constructor({ userId = null, email = null, role = "viewer" } = {}) {
    this.accessId = this.generateAccessId();
    this.userId = userId;
    this.email = email;
    this.role = role;
  }

  generateAccessId() {
    return Math.random().toString(36).slice(2, 11);
  }
}

export default SurveyWithVariants;
